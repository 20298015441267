import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`“I am late” – was his first thought after waking up. Today was Jack’s first day at Silicon Valley High school. As a hardworking, excellent student, his biggest fear was to be late. Jack quickly jumped out of bed. At the speed of a fireman, Jack dressed up. This included black trousers, a white shirt, a  tie and a suit-a perfect look of a pupil. After quickly brushing his teeth, Jack started working with his hair- brown, beautiful , but very unruly. Jack looked at the mirror. He saw a 15 year-old young man-tall, strong and handsome. Jack wiped his glasses , looked at the clock, and froze in shock. He was not late- it was only 7 am. Having nothing to do, Jack decided to read a math book-he needed to be prepared for the next lessons. He was moving to a new school and he was excited. A new school meant a  new impression. Maybe he would even be able to  make some new friends!`}</p>
    <p>{`During the same morning, Chuck had gotten up to the livid screech of the alarm clock. Even though the clock read 8:30, he did not rush. Similar to the most lazy students, he didn’t care if he would be late to class or not, even if it was his first day at school. He slowly dressed up- just a shirt and shorts. He was not picking the clothes a day before-he just chose the first thing that he saw. While eating breakfast , he saw an interesting clip on Youtube, then another one , and one more… as time flew  past, and he spent an hour scrolling through the phone. Finally, Chuck took a cab to school. He entered class at 10 o’clock with shocking placidity, paying no attention and having no worries about skipping his first class. It was Calculus class after all, and he hated it.`}</p>
    <p>{`The new school was not as friendly as Jack expected; he was always followed by sidelong glances. The only boy that wasn’t even looking at him was Chuck. Jack tried to make acquaintance with him, however Chuck ignored him. “Another school where I have no friends” was his first thought. Sadly, Jack went home.`}</p>
    <p>{`In Chuck’s old school, he was a popular guy. Everyone wanted to be friends with him because of his bravery and he was a fun sport. In this school, he became an “ignore me guy”. Being separate from the leaders of the class. The feeling of loneliness was absolutely new to him.`}</p>
    <p>{`When he got home, he was sweaty and exhausted. He checked for the television remote and turned the television on.`}</p>
    <p>{`While watching his favorite channel, Chuck received a notification on his phone congratulating him for winning the jackpot. He was confused, and taken by surprise. He checked who sent it and was taken aback when he realized it was “Stonks,” a lottery funding program by Microsoft to fund businesses by awarding a grant of five million dollars to the ten selected winners. It was a yearly lottery, and this year 2012, finally brought him some luck.`}</p>
    <p>{`He knew he had to invest in business, and couldn’t spend the money on something irrelevant. He knew what he had to do. During the next day, he would ask ‘the brain of the class’ for a partnership. He would ask Jack. But even then, this seemed to be random. What would his business be based on, what would they sell, and how does someone even manage a business…`}</p>
    <p>{`… “I really don’t know about that,” – said Jack. “We are  in school, and there will be exams soon. Let me think a bit.” Jack didn’t know what to answer-it looked like a wonderful  chance for him, however, on the second thought, if they would fail, he would just lose a lot of time he could use to work on math, science, chemistry and other subjects. Suddenly,he remembered a famous quote “You will never have a second chance. If you have one, use it. Without taking risks, you will never conquer the top.”`}<br parentName="p"></br>{`
`}{`On the next day, Jack agreed to work with Chuck. They both liked video games, so this became the topic of their company.`}</p>
    <p><strong parentName="p">{`Year 1:`}</strong>{` In 2012, “The Lords Of Tech” was opened. It was a huge success for boys and a very important event in the world. It had the newest , the most fashioned and the best video games ever. So, it was obvious that their company had the most users, clients and etc. In this year, they already reached a networth of 5.2 million dollars-their wealth was enormous.`}</p>
    <p><strong parentName="p">{`Year 2:`}</strong>{` During 2013, Jack and Chuck had finally reached a networth of 5.8 million U.S. dollars. After receiving a notification from Bill Gates himself who approved of the idea, Chuck came up with a brilliant idea to give Bill Gates a deal, a share of 50% of the company’s profit, in exchange of funding the initial starting of the company by offering an additional twenty million dollars. Bill Gates agreed, as he saw their company as the ‘new big thing’. This helped tremendously, as the company was able to expand much faster.`}</p>
    <p><strong parentName="p">{`Year 3:`}</strong>{` During 2014 Jack and Chuck had reached a networth of 7  million. News had spread around about their fame, and many people had mixed feelings about it. Especially when Jack and Chuck dropped out of high-school. This was their first risk, but they both knew it had to happen. They were both smart enough to be college dropouts, thanks to Jack tutoring Chuck. They were starting to develop new software that would allow a computer screen to fold. It would make the computer like a folded, hand-held fan.`}</p>
    <p><strong parentName="p">{`Year 4:`}</strong>{`  The moment that made Jack and Chuck marketing geniuses was when they bought a company for more than it was worth, which was around 1 million dollars. Yet Jack recognized the same potential in that company, as in his. The company they bought was  a small technology company called “ABCs.” They bargained for six million dollars. They ended up with seven. The reason he did this was because ABCs had something that Jack and Chuck’s didn’t have. Resources.`}</p>
    <p><strong parentName="p">{`Year 5:`}</strong>{` 2016 was a lucky year for the “Lords of Techs” company. After buying ABCs they were able to expand further and reach a networth of 500 million. They were 19 and 20, which was still unbelievable. They also started selling new products, merchandise, and an online book store - just like how Jeff Bezos started. This was Jack’s idea because he knew a lot more about business than Chuck. But Chuck was still extremely helpful. He knew what books people liked, and he offered to take control of the book store. Chuck also suggested they include courses for high-school students.`}</p>
    <p><strong parentName="p">{`Year 6:`}</strong>{` It was 2017 when Chuck and Jack fought for the first time.  This year they had reached a networth of 1.2 billion dollars. Chuck thought it was a good idea to buy the 700 million dollar company  Techmaster. Jack thought this was too risky and might cause them to go bankrupt. This fight was serious. For once Jack was wrong. But at the time it looked too risky.  They ended up flipping a coin. Chuck won and the company was bought.`}</p>
    <p>{`And it began like this, with more expansions as Jack and Chuck found clever ways to earn more money. Their net worths were increasing with such rapidity that by the age of 25 they became the youngest richest people on the planet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      